<template>
    <div class="d-flex flex-column min-vh-100">
        <Header />
         <div class="subheader">
           <h3>Iniciar sesión</h3>
        </div>

        <div class="container" style="margin-top:30px">
            <div class="row">
               <form>
                    <div class="form-group">
                        <label for="exampleInputEmail1">Correo electrónico</label>
                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" v-model="email">
                    </div>
                    <div class="form-group">
                        <label for="exampleInputPassword1">Contraseña</label>
                        <input type="password" class="form-control" id="exampleInputPassword1" v-model="password">
                        <small id="emailHelp" class="form-text text-muted">Olvidé mi contraseña</small>
                    </div>
                    <button type="button" class="btn btn-primary" @click="login">Iniciar sesión</button>
                </form>

            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import axios from  'axios';

export default {
  components:{
      Header,
      Footer
  },
  data(){
      return {
          email: '',
          password: ''
      }
  },
  name: 'Login',
  created(){
      console.log(this.$store.getters.getUser);
  },
  methods: {
      login() {
          let dataToSend = {email: this.email, password: this.password}
          axios.post(process.env.VUE_APP_APIURL + 'Auth', dataToSend)
            .then((response) => {
                if(response.data.error == true) {
                     alert(response.data.error_message)
                    return;
                }
                let user = {isLogged: true, ...response.data.data}
                this.$store.dispatch('loginUser', user);
                this.$router.push('/profile');
            })
            .catch(function (error) {
                alert(error.response.data.error_message)

            })
      }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.bg-darkBlue{
    background: #152F44
}
.btn-black{ background:#000; color:#FFF; font-size:14px; border:1px solid #979797;}
.btn-black:hover{color:#FFF; background:#333232}
.btn-golden{ background:#AA8D5D; color:#FFF; font-size:14px; border:1px solid #9B7676;}
.btn-golden:hover{color:#FFF; background:#C4A167}

.navbar-light .navbar-nav .nav-link{ color:#FFF}
.navbar-light .navbar-nav .nav-link:hover{ background:#C4A167}
.btn-outline-white{
    border:1px solid #FFF;
    color:#FFF
}
.carousel-caption {top:30%}
.carousel-caption h5{ margin-bottom:30px}
.section{margin:60px 0}
footer{margin-top:60px}
.subheader{ background:#D1C1A7; color:#FFF; display: flex;
  align-items: center;
  justify-content: center; height:250px}
  .course:hover{
      opacity:.8
  }
</style>
